.loader {
  position: relative;
  width: 280px;
  height: 376px;
  margin-bottom: 10px;
  border: 1px solid #d3d3d3;
  padding: 15px;
  background-color: #e3e3e3;
  overflow: hidden;
  border-radius: 5px;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 1.2s linear infinite;
}

.loader .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.loader .wrapper > div {
  background-color: #cacaca;
}

.loader .circle {
  width: 100%;
  height: 200px;
  border-radius: 0%;
}

/* .loader .button {
  display: inline-block;
  height: 32px;
  width: 75px;
} */

.loader .line-1 {
  position: absolute;
  top: 209px;
  left: 60px;
  height: 20px;
  width: 100px;
}

.loader .line-2 {
  position: absolute;
  top: 238px;
  left: 60px;
  height: 20px;
  width: 150px;
}

.loader .line-3 {
  position: absolute;
  top: 272px;
  left: 0px;
  height: 20px;
  width: 100%;
}

.loader .line-4 {
  position: absolute;
  top: 305px;
  left: 0px;
  height: 40px;
  border-radius: 4px;

  width: 47%;
}
.loader .line-5 {
  position: absolute;
  top: 305px;
  left: 132px;
  border-radius: 4px;
  height: 40px;
  width: 47%;
}
.loader .line-6 {
  position: absolute;
  top: 209px;
  left: 0;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
@keyframes ldio-4j5ay0xf86g {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.ldio-4j5ay0xf86g div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-4j5ay0xf86g linear 1s infinite;
  background: #075985;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}

.ldio-4j5ay0xf86g div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #075985;
}

.ldio-4j5ay0xf86g div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #075985;
}

.loadingio-spinner-spinner-977el9wwy2v {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-4j5ay0xf86g {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-4j5ay0xf86g div {
  box-sizing: content-box;
}
